<template>
  <v-row @keydown.enter="connect">
    <v-col offset="3" cols="6" class="my-4">
      <v-card>
        <v-card-title>{{ $t("connect.connectYou") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form v-model="validate">
            <v-text-field
              :rules="[v => !!v || $t('connect.noLogin')]"
              v-model="login"
              :label="$t('connect.login')"
            ></v-text-field>
            <v-text-field
              :rules="[v => !!v || $t('connect.noPsw')]"
              type="password"
              v-model="psw"
              :label="$t('connect.psw')"
            ></v-text-field>
          </v-form>
          <v-alert v-if="message" type="error">{{ $t(message) }}</v-alert>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn tile :disabled="!validate && !disabled" @click="connect">
            {{ $t("button.connect") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { getURLBase } from "@/utils";

export default {
  created() {
    if (this.$route.query.token) {
      this.setToken({ token: this.$route.query.token });
      this.getInfo();
    }
  },

  data: () => ({
    validate: false,
    login: "",
    psw: "",
    disabled: false,
    error: null,
    message: null
  }),

  computed: {},

  methods: {
    ...mapActions("user", ["getToken", "getUser"]),
    ...mapActions("menus", ["fetchMenus"]),
    ...mapMutations("user", ["setToken"]),
    ...mapMutations("menus", ["setWithNav"]),
    connect() {
      if (!this.validate && !this.disabled) {
        return false;
      }
      this.$root.$overlay.show();
      this.disabled = true;
      this.error = false;
      this.getToken({ login: this.login, password: this.psw })
        .then(() => this.getInfo())
        .catch(err => {
          this.disabled = false;
          this.error = true;
          this.$root.$overlay.hide();
          this.message = err.exceptionType || "errors.errorToken";
        });
    },
    async getInfo() {
      await this.getUser()
        .then(async () => {
          this.fetchMenus().then(() => {
            window.localStorage.setItem("navBar", "true");
            this.setWithNav(true);
            this.$root.$overlay.hide();
            this.$router.push("/clientManagement");
          });
        })
        .catch(() => {
          if (process.env.NODE_ENV != "development")
            parent.window.location.href = getURLBase();
          else {
            localStorage.removeItem("token");
            window.location.href = window.location.host;
          }
        });
    }
  }
};
</script>
